import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Common/Header';
import Sidebar from '../components/Common/Sidebar';
function Displaywarrantyregister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [warrantyData, setWarrantyData] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModalusermanual, setshowModalusermanual] = useState(false);
  const data = localStorage.getItem('vinnData');
  const parsed_data = JSON.parse(data);
  //   const [warrantyData, setWarrantyData] = useState({});

  const db_data = parsed_data?.data;
  // Define the isLoaded state
  React.useEffect(() => {
    // console.log("data=--_>> ",localStorage.getItem("vinnData"))
    if (!localStorage.getItem('vinnData')) {
      navigate('/vinn');
    } else {
      getApiData();
    }
  }, []);
  const [hasAssembling, setHasAssembling] = useState(false);
  const [hasWarrantyRegistration, setHasWarrantyRegistration] = useState(false);
  React.useEffect(() => {
    const fetchAssemblingAndWarrantyData = async () => {
      console.log('Fetching assembling and warranty data...');
      try {
        // Fetch assembling data
        const assemblingResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/assembling/product/${db_data?.id}/`
        );
        const assemblingData = await assemblingResponse.json();
        console.log('Assembling Data:', assemblingData);
        const hasAssemblingData = assemblingData.length > 0;
        setHasAssembling(hasAssemblingData);

        if (hasAssemblingData) {
          // Fetch warranty registration data if assembling data exists
          const warrantyResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
          );
          const warrantyData = await warrantyResponse.json();
          console.log('Warranty Data:', warrantyData);
          setWarrantyData(warrantyData[0]);
          setHasWarrantyRegistration(warrantyData.length > 0);
          console.log('Warranty hghghghghgfnewData:', warrantyData);
        }

        setIsLoaded(true); // Set loading state to true when data fetching is complete
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    if (db_data?.id) {
      fetchAssemblingAndWarrantyData();
    }
  }, [db_data?.id]);
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous route
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits (e.g., '03' instead of '3')
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0'); // Ensure hour is 2 digits
    const minute = String(date.getMinutes()).padStart(2, '0'); // Ensure minute is 2 digits

    return `${day}/${month}/${year} at ${hour}:${minute}`;
  };
  //   React.useEffect(() => {
  //     const fetchWarrantyData = async () => {
  //       try {
  //         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`);
  //         const data = await response.json();

  //         // Check if data is an array and has at least one item
  //         if (Array.isArray(data) && data.length > 0) {
  //           setWarrantyData(data[0]); // Access the first item
  //         } else {
  //           console.error("Data is not in the expected format or is empty");
  //           setWarrantyData(null);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching warranty data:", error);
  //         setWarrantyData(null);
  //       }
  //     };

  //     if (db_data?.id) {
  //       fetchWarrantyData();
  //     }
  //   }, [db_data?.id]);
  //   const fetchDataByLanguageusermanual = (language) => {
  //     setshowModalusermanual(false);

  //     // Construct the API URL using the selected language and db_data
  //     const apiUrl = `${process.env.REACT_APP_API_URL}/api/filter_user_manual/${language}/${db_data?.id}/`;

  //     fetch(apiUrl)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         console.log("Fetched data:", data);
  //         if (data.user_manual_pdf) {  // Check if data is not null and user_manual_pdf exists
  //           // Download the PDF file
  //           downloadPdfFile(`${process.env.REACT_APP_API_URL}/${data.user_manual_pdf}`);
  //         } else {
  //           toast.error('No user manual found', {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data:', error);
  //         alert('Error fetching data. Please try again later.');
  //       });
  //   };
  const handlemainButtonClick = () => {
    if (!hasAssembling) {
      toast.error('Please fill assembling.');
    } else if (hasAssembling && !hasWarrantyRegistration) {
      toast.error('Fill the vehicle registration first.');
    } else if (hasAssembling && hasWarrantyRegistration) {
      navigate('/service/addNewService');
    }
  };

  const getApiData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${db_data?.sku}/history/`
      // `${process.env.REACT_APP_API_URL}/products/15/history/`
    );
    if (res?.data?.length) {
      const reversedData = res?.data;
      setDb_Data(res?.data);
    }
  };

  const [db_data_history, setDb_Data] = useState(null);
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();

  React.useEffect(() => {
    getApiData();
  }, []);
  const handleButtonClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/bulletins/${db_data.vin_code}/`
      );

      if (response.data.length > 0 /* Check if data is as expected */) {
        // Store data in local storage
        localStorage.setItem('bulletinData', JSON.stringify(response.data));
        console.log(response.data);
        // Change button color if data is present
        setButtonStyle({ border: '1px solid #009bb4', color: 'red' });

        // Navigate to '/service/maintenance'
        navigate('/service/bulletin');
      } else {
        // Show alert if no data
        toast.error('No data found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // // Reverse the order of the items array
  // items.reverse();
  const image_url = `${db_data?.image}`;
  return (
    <>
      <div className='flex'>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          {/* VEHICLE DATA */}
          <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
            <div className='flex flex-col md:flex-row'>
              {/* LEFT */}
              <div className='w-full md:w-1/2 md:pt-3 xl:pt-12'>
                {/* IMAGE */}
                <div className='mb-10 md:mb-20'>
                  <img src={image_url} alt='bike-img' />
                </div>
              </div>

              {/* RIGHT */}
              <div className='w-full flex flex-col md:w-1/2'>
                {/* LIST */}
                <div className='md:pl-5 xl:pl-[30px]'>
                  {/* BIKE NAME */}
                  <div className='mb-5 md:mb-[30px] xl:mb-14'>
                    <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_20_l_25 xl:text-f_27_l_34 text-center font-supremeBold'>
                      {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                    </h3>
                    {/* <button className="w-full mt-5 h-12 xl:h-[73px] rounded-[10px] border border-yellow text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase"onClick={() => navigate('/service/Waranty')}>
                    Warranty Registration
                    </button> */}
                  </div>

                  {/* LIST */}
                  <div className='space-y-3.5 xl:space-y-5'>
                    {/* new formation start */}
                    {/* {db_data?.status?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Status
                        </span>
                        <span> {db_data?.status}</span>
                      </div>
                    )} */}
                    {db_data?.Manufacturer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Manufacturer')}
                        </span>
                        <span> {db_data?.Manufacturer}</span>
                      </div>
                    )}
                    {db_data?.series?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('SERIES')}
                        </span>
                        <span>{db_data?.series}</span>
                      </div>
                    )}
                    {db_data?.Factory_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('FACTORY_NAME')}
                        </span>
                        <span>{db_data?.Factory_name}</span>
                      </div>
                    )}
                    {db_data?.model_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('MODEL_NAME')}
                        </span>
                        <span>{db_data?.model_name}</span>
                      </div>
                    )}
                    {db_data?.eu_type?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('EU_TYPE_APPROVAL')}
                        </span>
                        <span>{db_data?.eu_type}</span>
                      </div>
                    )}
                    {db_data?.steering_power?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('STEERING_POWER')}
                        </span>
                        <span>{db_data?.steering_power}</span>
                      </div>
                    )}
                    {db_data?.wheels?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('WHEELS')}
                        </span>
                        <span>{db_data?.wheels}</span>
                      </div>
                    )}
                    {db_data?.color?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('COLOR')}
                        </span>
                        <span>{db_data?.color}</span>
                      </div>
                    )}
                    {db_data?.lights?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('LIGHTS')}
                        </span>
                        <span>{db_data?.lights}</span>
                      </div>
                    )}
                    {db_data?.screen?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('SCREEN')}
                        </span>
                        <span>{db_data?.screen}</span>
                      </div>
                    )}
                    {db_data?.cargo_compartment?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('CARGO_COMPARTMENTS')}
                        </span>
                        <span>{db_data?.cargo_compartment}</span>
                      </div>
                    )}
                    {db_data?.communication_terminal?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('COMMUNICATION_TERMINAL')}
                        </span>
                        <span>{db_data?.communication_terminal}</span>
                      </div>
                    )}
                    {db_data?.engine_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Engine_Name')}
                        </span>
                        <span>{db_data?.engine_name}</span>
                      </div>
                    )}
                    {db_data?.engine_number?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Engine_Number')}
                        </span>
                        <span>{db_data?.engine_number}</span>
                      </div>
                    )}
                    {db_data?.country?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {' '}
                          {t('COUNTRY')}
                        </span>
                        <span>{db_data?.country}</span>
                      </div>
                    )}
                    {db_data?.sku?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>SKU</span>
                        <span> {db_data?.sku}</span>
                      </div>
                    )}
                    {db_data?.year?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('REGISTRATION_YEAR')}
                        </span>
                        <span> {db_data?.year}</span>
                      </div>
                    )}
                    {db_data?.owner_company?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Owner company
                        </span>
                        <span> {db_data?.owner_company}</span>
                      </div>
                    )}

                    {db_data?.owner_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_NAME')}
                        </span>
                        <span>{db_data?.owner_name}</span>
                      </div>
                    )}
                    {/* ORDERER EMAIL */}
                    {db_data?.owner_email?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_EMAIL')}
                        </span>
                        <span>{db_data?.owner_email}</span>
                      </div>
                    )}
                    {/* ORDERER PHONE */}
                    {db_data?.owner_phone?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_PHONE')}
                        </span>
                        <span>{db_data?.owner_phone}</span>
                      </div>
                    )}

                    {db_data?.plate_number?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Plate_number')}
                        </span>
                        <span>{db_data?.plate_number}</span>
                      </div>
                    )}
                    {db_data?.vin_code?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('VIN_CODE')}
                        </span>
                        <span>{db_data?.vin_code}</span>
                      </div>
                    )}
                    {/* {db_data?.Production_date?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Production date
                        </span>
                        <span>{db_data?.Production_date}</span>
                      </div>
                    )}
                    {db_data?.date_of_manufacture?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          Factory out date
                        </span>
                        <span>{db_data?.date_of_manufacture}</span>
                      </div>
                    )} */}
                    {db_data?.ecu_map?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Ecu map
                        </span>
                        <span>{db_data?.ecu_map}</span>
                      </div>
                    )}
                    {db_data?.bar_code?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Bar code
                        </span>
                        <span>{db_data?.bar_code}</span>
                      </div>
                    )}
                    {db_data?.warranty_end_date?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('Warranty_end_date')}
                        </span>
                        <span>
                          {new Date(db_data?.warranty_end_date) <=
                          new Date('2000-01-01')
                            ? 'Expired'
                            : db_data?.warranty_end_date}
                        </span>
                      </div>
                    )}
                    {db_data?.warranty_ext_end_date?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          Warranty ext end date
                        </span>
                        <span>
                          {new Date(db_data?.warranty_ext_end_date) <=
                          new Date('2000-01-01')
                            ? 'Expired'
                            : db_data?.warranty_ext_end_date}
                        </span>
                      </div>
                    )}

                    {/* new formation end */}

                    {/* SKU */}

                    {/* VIN CODE / PRODUCT ID */}

                    {/* COUNTRY */}

                    {/* SERIES */}

                    {/* MODEL NAME */}

                    {/* FACTORY NAME */}

                    {/* COLOR */}

                    {/* EU TYPE APPROVAL */}

                    {/* BODY TYPE */}
                    {db_data?.body_type?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          BODY TYPE
                        </span>
                        <span>{db_data?.body_type}</span>
                      </div>
                    )}

                    {/* STEERING POWER */}

                    {/* WHEELS */}

                    {/* SCREEN */}

                    {/* LIGHTS */}

                    {/* CARGO COMPARTMENTS */}

                    {/* COMMUNICATION TERMINAL */}

                    {/* DATA OF MANUFACTURE */}

                    {/* ORDERER */}

                    {/* IMPORTER */}
                    {db_data?.importer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('IMPORTER')}
                        </span>
                        <span>{db_data?.importer}</span>
                      </div>
                    )}

                    {/* DEALER */}
                    {db_data?.dealer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('DEALER')}
                        </span>
                        <span>{db_data?.dealer}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* HISTORY SECTION */}

            <div className='mt-10 mb-4'>
              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerName'
                  className='text-[#FFD009] font-supremeBold bg-gradient-text text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_NAME')}
                </label>
                <input
                  value={warrantyData?.owner_name || ''}
                  id='ownerName'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  // Replace "Assigned Value" with the actual value you want to set
                  readOnly
                  // onChange={(event) => onChangeValue(event, "owner_name")}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='companyName'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('Company')}
                </label>
                <input
                  id='companyName'
                  value={warrantyData?.company || ''}
                  readOnly
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  // onChange={(event) => onChangeValue(event, "company")}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerPhone'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_PHONE')}
                  {/* <span className="text-error">*</span> */}
                </label>
                {/* <span className="text-error">*</span> */}
                <input
                  id='ownerPhone'
                  type='text'
                  value={warrantyData?.owner_phone || ''}
                  readOnly
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  // onChange={(event) => onChangeValue(event, "owner_phone")}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerEmail'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_EMAIL')}
                </label>
                <input
                  id='ownerEmail'
                  type='text'
                  value={warrantyData?.owner_email || ''}
                  readOnly
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  // onChange={(event) => onChangeValue(event, "owner_email")}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='vehiclePlateNumber'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('VEHICLE_NUMBER_PLATE')}
                </label>
                <input
                  id='vehiclePlateNumber'
                  type='text'
                  value={warrantyData?.vehicle_number_plate || ''}
                  readOnly
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  // onChange={(event) => onChangeValue(event, "owner_email")}
                />
              </div>

              <div className='w-full items-start mb-10'>
                <div className='mb-10'>
                  <label
                    htmlFor='vehicleHandover'
                    className='font-supremeRegular text-2xl md:w-1/5'
                  >
                    {t('registration_desc')}
                  </label>
                </div>
                <div>
                  <input
                    id='vehicleHandover'
                    value={warrantyData?.necessary_instructions || ''}
                    readOnly
                    type='text'
                    className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                    // onChange={(event) => onChangeValue(event, "necessary_instructions")}
                  />
                </div>
              </div>
            </div>
            <div className='flex items-center justify-center w-full mb-10'>
              <div className='bg-gradient-text font-supremeBold text-2xl w-auto px-4 py-2 text-center'>
                {t('CREATED_BY', {
                  user: warrantyData?.user?.name || 'unknown_user',
                  date: warrantyData?.created_at
                    ? formatDate(warrantyData.created_at)
                    : 'N/A',
                  company:
                    warrantyData?.user?.company?.company_name ||
                    'unknown_company',
                })}
              </div>
            </div>
            <div className='flex justify-center items-center'>
              {' '}
              {/* Add items-center if vertical centering is also needed */}
              <button
                className='w-[240px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium'
                onClick={handleBackClick}
              >
                {/* {t("Save_warranty_data") } */}
                Back
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Displaywarrantyregister;
