import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('selectedLanguage');
const defaultLanguage = savedLanguage ? JSON.parse(savedLanguage).value :'EN';

i18n
  .use(initReactI18next)
  .init({
    resources:{
      EN:{
        translation:{
          //dashboard,
          dasboard_learn: 'Learn How to use this System',
          DESC_USE: 'Here you will find simple tutorials on how to use the CFMOTO WORLD software. A new user needs to read carefully and understand how the system works. We have created guides for the dealer, the service person and the vehicle owner.',
          Step_1: 'Step 1',
          Step_1_desc:'Everyone who joins the system identifies themselves and the system identifies a specific person. Each person is personally responsible for the requests made with their user account and the data entered.',
          Step_2: 'Step 2',
          Step_2_desc:'Each user group can perform queries, enter data and view the previous history of the vehicle according to the permitted rights.',
          Step_3: 'Step 3',
          Step_3_desc:'Dear partners, all the information entered here is necessary for you and other partners. To simplify vehicle maintenance and to know how to perform the best maintenance for this particular vehicle.',
          search_by_VIN:'Search by VIN ...',
          select_company:'Select Company',
          select_workshop:'Select Workshop',
          add_new_work:'Add New Work',
          pending_db:'Pending',
          workshop_db:'Workshop',
          completed_db:'Completed',
          created_at_db: 'Created',//:{{date}} at {{time}}',
          at_db:'at',
          maintenance_db:'Maintenance',
          repairing_db:'Repairing',
          warranty_db:'Warranty',
          //sidebar,
          Dashboard: 'Dashboard',
          Warranty_end_date: 'Warranty end date',
          Plate_number: 'Plate number',
          REGISTRATION_YEAR: 'REGISTRATION YEAR',
          Manufacturer: 'Manufacturer',
          Engine_Name: 'Engine name',
          Engine_Number: 'Engine number',
          work: 'work',
          works: 'works',
          add: 'Add',
          MY: 'My',
          pending: 'Pending',
          finished: 'Finished',
          Vehicle: 'Vehicle',
          Maintenance: 'Maintenance',
          Repairing: 'Repairing',
          Parts: 'Parts',
          Accessories: 'Accessories',
          Service_manual: 'Service manual',
          User_manual: 'User manual',
          Warranty: 'WARRANTY',
          Warrantynew: 'Warranty',
          Bulletins: 'Bulletins',
          Log_out: 'Vehicle Log Out',
          //vehicle data,
          Warranty_Registration: 'Warranty Registration',
          History: 'HISTORY',
          SKU: 'SKU',
          VIN_CODE: 'VIN CODE',
          COUNTRY: 'COUNTRY',
          SERIES: 'SERIES',
          MODEL_NAME: 'MODEL NAME',
          FACTORY_NAME: 'FACTORY NAME',
          COLOR: 'COLOR',
          EU_TYPE_APPROVAL: 'EU TYPE APPROVAL',
          STEERING_POWER: 'STEERING POWER',
          WHEELS: 'WHEELS',
          SCREEN: 'SCREEN',
          LIGHTS: 'LIGHTS',
          CARGO_COMPARTMENTS: 'CARGO COMPARTMENTS',
          COMMUNICATION_TERMINAL: 'COMMUNICATION TERMINAL',
          OWNER_NAME: 'OWNER NAME',
          OWNER_PHONE: 'OWNER PHONE',
          OWNER_EMAIL: 'OWNER EMAIL',
          VEHICLE_NUMBER_PLATE: 'VEHICLE NUMBER PLATE',
          IMPORTER: 'IMPORTER',
          DEALER: 'DEALER',
          //maintenance,
          Select_the_mileage: 'Select the mileage',
          next: 'Next',
          Mileage_at_maintenance: 'Mileage at maintenance',
          Maintenance_description: 'Maintenance description',
          Spare_parts_for_service: 'Spare parts for service:',
          Vehicle_photos: 'Vehicle photos:',
          Vehicle_photos_desc: 'Photo of the speedometer (numbers must be legible), VIN code (numbers must be legible), 45° front view and 45° rear view',
          Maintainence_list_desc: 'Maintenance service checklist',
          Maintainence_list_desc_2: 'Please mark when you finish each section',
          Mechanic_comment: 'Mechanic comment:',
          Maintainence_button_text: 'Save maintenance Data',
          check_all_m:'Check All',
          comment_m:'Comment',
          //warranty,
          waranty_milage: 'Mileage at service',
          Failure_description: 'Failure description',
          Upload_photos_and_videos: 'Upload photos and videos',
          Replaced_parts: 'Replaced parts',
          Cause: 'Cause',
          repair: 'Repair',
          Remarks: 'Remarks',
          Review: 'Review',
          Save_warranty_data: 'Save warranty data',
          Update_warranty_data: 'Update warranty data',
          //repairing,
          Mileage_of_Repairing: 'Mileage of Repairing',
          Customer_description_of_errors: 'Vehicle Owner Description',
          The_receiver_oftheworks_description: 'Repairing Description',
          Feedback_from_the_mechanic: 'Feedback from the mechanic',
          Save_repairing_Data: 'Save repairing data',
          Update_repairing_data: 'Update repairing data',
          //bulletin,
          Bulletins_description: 'Bulletins description:',
          Factory_instructions: 'Factory instructions',
          OPEN_FILE: 'OPEN FILE',
          bulettin_Mechanic_comment: 'Mechanic comment:',
          Bulletin_executed: 'Bulletin executed',
          //warranty registration,
          Company: 'COMPANY',
          registration_desc:'The vehicle has been handed over to the owner. All the necessary briefing has been given to the owner.',
          //vin number page,
          EnterVINnumber: 'Enter VIN/Plate number',
          vinnumber: 'VIN number',
          platenumber: 'Plate number',
          SUBMIT: 'SUBMIT',
          OR: 'or',
          Addspeedometer: 'Add speedometer',
          addvincode: 'Add VIN code',
          add45front: 'Add 45° front view',
          add45back: 'Add 45° rear view',
          addleft: 'Add left view',
          addright: 'Add right view',
          instruction: 'Name of the Person who was Instructed',
          company_instruction: 'Only if the vehicle is sold to a Company',
          //assembling,
          check_the_vin_code: 'Check the vin code',
          and_make_photo: 'and make photo',
          assembly_confirmation: 'I confirm that the assembly was carried out according to the instructions.',
          mechanic_comment: 'Mechanic comment',
          issues_found: 'Issues found',
          completed_assembly: 'Completed assembly',
          issues_found_during_the_assembly_process:'Issues found during the assembly process.',
          open_warranty: 'Open warranty',
          add_photo: 'Add photo',
          add_video: 'Add video',
          assembling: 'ASSEMBLING',
          //add work,
          ADD_WORK: 'Add Work',
          VEHICLE_OWNER_DESCRIPTION: 'Vehicle Owner Description',
          WRITE_VEHICLE_OWNER_DESCRIPTION: 'Write vehicle owner description',
          CURRENT_MILEAGE: 'The current mileage is approx',
          KILOMETERS: 'km',
          MAINTENANCE: 'Maintenance',
          LAST_SERVICE_MESSAGE: 'The Last service was performed on {{date}}, and the mileage was {{mileage}} km',
          NO_SERVICE_HISTORY: 'This vehicle has no previous service history in the CFMOTO World system.',
          REPAIRING: 'Repairing',
          REPAIRING_DESCRIPTION: 'Repairing Description',
          DESCRIPTION_TO_MECHANIC: 'Description to mechanic',
          WARRANTY:'Warranty',
          WARRANTY_DESCRIPTION:'Warranty description',
          WORK_ORDERER_NAME: 'Work Orderer Name',
          ENTER_WORK_ORDERER_NAME: "Enter work orderer's name",
          WORK_ORDERER_EMAIL: 'Work Orderer Email',
          ENTER_WORK_ORDERER_EMAIL: "Enter work orderer's email",
          WORK_ORDERER_PHONE: 'Work Orderer Phone Number',
          ENTER_WORK_ORDERER_PHONE: "Enter work orderer's phone number",
          CREATED_BY: 'Created by {{user}} at {{date}} from company {{company}}',
          add_work_to_queue: 'Add to work queue',
          Continue: 'Open checklist',
          Repairing: 'Repairing',
          //confirmation,
          confirmation_c:'Confirmation',
          confirmation_text_c:'Are you sure you want to log out of this vehicle?',
          confirm_c:'Confirm',
          cancel_c:'Cancel',
          //user,
          log_out_user:'Logout',
          reset_password: 'Reset Password',
          Select_Bulletin: 'Select Bulletin',
          Mark_as_viewed: 'Mark as viewed',
          review_progress: 'Review Progress',
          Update_maintenance_data: 'Update Maintenance data',
          assembling_created_successfully: "Assembling created successfully",
          Issued_To_Owner: 'Issued to Owner',
          confirmation_text_issue: 'Are you sure you want to release the vehicle to the owner?',
          confirmation_text_history: 'We are making history with vehicle release! ',          
        }
      },
      ET:{
        translation:{
          //dashboard,
          dasboard_learn: 'Õpi süsteemi kasutama',
          DESC_USE: 'Siit leiad CFMOTO WORLD programmi kasutusjuhendid. Uus kasutaja peaks juhendid hoolikalt läbi lugema ning endale süsteemi selgeks tegema. Kasutusjuhendid oleme loonud edasimüüjale, hooldajale ning sõiduki omanikule.',
          Step_1: 'Samm 1',
          Step_1_desc: 'Süsteemiga ühinedes, kasutaja tuvastab enda isiku. Iga isik on vastutav enda kasutaja alt sisestatud info ja andmete korrektsuses.',
          Step_2: 'Samm 2',
          Step_2_desc:'Iga kasutajagrupp saab oma kasutaja õigustele vastavalt teha päringuid, sisestada andmeid ning näha ajalugu.',
          Step_3: 'Samm 3',
          Step_3_desc: 'Austatud partnerid, kogu informatsioon on teile ning teistele partneritele vajalik hoolduse süsteemi lihtsustamiseks ning parima hoolduse teostamiseks kindlale sõidukile.',
          search_by_VIN:'Otsin VIN koodi ...',
          select_company:'Vali firma',
          select_workshop:'Vali filiaal',
          add_new_work:'Lisa uus töökäsk',
          pending_db:'Ootel',
          workshop_db:'Töökoda',
          completed_db:'Valmis',
          created_at_db: 'Loodud',//:{date}} kell {{time}}',
          at_db:'kell',
          maintenance_db:'Hooldus',
          repairing_db:'Remont',
          warranty_db:'Garantii',
          //sidebar,
          Dashboard:'Juhtpaneel',
          Warranty_end_date:'GARANTII LÕPP KUUPÄEV',
          Plate_number:'REG.NUMBER',
          REGISTRATION_YEAR:'REGISTREERIMIS AASTA',
          Manufacturer:'tootja',
          Engine_Name:'MOOTORI TÄHIS',
          Engine_Number:'MOOTORI NUMBER',
          work:'töökäsk',
          works:'töökäsk',
          add:'Lisa',
          MY:'Minu',
          pending:'Ootel',
          finished:'Lõpetatud',
          Vehicle:'Sõiduk',
          Maintenance:'Hooldus',
          Repairing:'Remont',
          Parts:'Varuosad',
          Accessories:'Lisavarustus',
          Service_manual:'Hoolduse juhend',
          User_manual:'Kasutusjuhend',
          Warranty:'GARANTII',
          Warrantynew:'Garantii',
          Bulletins:'Teated',
          Log_out:'Logi välja sõidukist',
          //vehicle data,
          Warranty_Registration:'Garantii registreerimine',
          History:'AJALUGU',
          SKU:'SKU',
          VIN_CODE:'VIN KOOD',
          COUNTRY:'RIIK',
          SERIES:'SEERIA',
          MODEL_NAME:'MUDEL',
          FACTORY_NAME:'TEHASETÄHIS',
          COLOR:'VÄRVUS',
          EU_TYPE_APPROVAL:'EU TÜÜBIKINNITUS',
          STEERING_POWER:'ROOLIVÕIMENDI',
          WHEELS:'VELJED',
          SCREEN:'EKRAAN',
          LIGHTS:'TULED',
          CARGO_COMPARTMENTS:'PANIPAIGAD',
          COMMUNICATION_TERMINAL:'T-BOX',
          OWNER_NAME:'OMANIKU NIMI',
          OWNER_PHONE:'OMANIKU TELEFON',
          OWNER_EMAIL:'OMANIKU E-MAIL',
          VEHICLE_NUMBER_PLATE:'REGISTREERIMIS NUMBER',
          IMPORTER:'MAALETOOJA',
          DEALER:'MÜÜJA',
          //maintenance,
          Select_the_mileage:'Valige läbisõit',
          next:'järgmiseks',
          Mileage_at_maintenance:'Hoolduse läbisõit',
          Maintenance_description:'Hoolduse kirjeldus',
          Spare_parts_for_service:'Hoolduse varuosad:',
          Vehicle_photos:'Sõiduki pildid:',
          Vehicle_photos_desc:'Pilt spidomeetrist ( peab olema loetav ), VIN kood ( peab olema loetav ), 45° eestvaade ja 45° tagantvaade',
          Maintainence_list_desc:'Hoolduse kontrollnimekiri',
          Maintainence_list_desc_2:'Palun märgi peale iga sammu lõpetamist',
          Mechanic_comment:'Mehaaniku kommentaar:',
          Maintainence_button_text:'Salvesta hoolduse andmed',
          check_all_m:'Märgi kõik',
          comment_m:'Lisa märkus',
          //warranty,
          waranty_milage:'Sõiduki läbisõit ',
          Failure_description:'Vea kirjeldus',
          Upload_photos_and_videos:'Piltide ja videote üles laadimine',
          Replaced_parts:'Vahetatud varuosad',
          Cause:'Põhjuse kirjeldus',
          repair:'Remont',
          Remarks:'Märkused',
          Review:'Ülevaade',
          Save_warranty_data:'Salvesta garantii andmed',
          Update_warranty_data:'Uuenda garantii andmeid',
          //repairing,
          Mileage_of_Repairing:'Läbisõit',
          Customer_description_of_errors:'Sõiduki omaniku kirjeldus',
          The_receiver_oftheworks_description:'Remondi kirjeldus',
          Feedback_from_the_mechanic:'Mehaaniku tagasiside',
          Save_repairing_Data:'Salvesta remonttöö andmed',
          Update_repairing_data:'Uuenda remonttöö andmeid',
          //bulletin,
          Bulletins_description:'Teadete kirjeldus:',
          Factory_instructions:'Tehase juhised',
          OPEN_FILE:'AVA FAIL',
          buletin_Mechanic_comment:'Mehaaniku kommentaar:',
          Bulletin_executed:'Bulletin executed',
          //warranty registration,
          Company:'ETTEVÕTE',
          registration_desc:'Sõiduk on omanikule üle antud. Omanikule on tehtud kogu vajalik instruktaaž.',
          //vin number page,
          EnterVINnumber:'Sisesta VIN kood või sõiduki reg.number',
          vinnumber:'VIN number',
          platenumber:'Numbrimärk',
          SUBMIT:'SISENE SÕIDUKISSE',
          OR:'või',
          Addspeedometer:'Lisa spidomeeter',
          addvincode:'Lisa VIN-kood',
          add45front:'Lisage 45° eestvaade',
          add45back:'Lisage 45° tagantvaade',
          addleft:'Lisa vasak vaade',
          addright:'Lisa parem vaade',
          instruction:'Inimese nimi kellele on tehtud sõiduki kohta instruktaaž',
          company_instruction:'Täita ainult siis kui müüdud ettevõttele',
          //assembling,
          check_the_vin_code:'Kontrollige vin-koodi',
          and_make_photo:'ja tee foto',
          assembly_confirmation:'Kinnitan, et kokkupanek viidi läbi vastavalt juhistele.',
          mechanic_comment:'Mehaaniku kommentaar ',
          issues_found:'Ava garantii juhtum',
          completed_assembly:'Komplekteerimine teostatud',
          issues_found_during_the_assembly_process:'Komplekteerimise käigus leitud probleemid.',
          open_warranty:'Ava garantii juhtum',
          add_photo:'Lisa foto',
          add_video:'Lisa video',
          assembling:'SÕIDUKI KOMPLEKTEERIMINE',
          //add work,
          ADD_WORK:'Lisa töökäsk',
          VEHICLE_OWNER_DESCRIPTION:'Sõiduki omaniku kirjeldus',
          WRITE_VEHICLE_OWNER_DESCRIPTION:'Kirjuta sõiduki omaniku kirjeldus',
          CURRENT_MILEAGE:'Praegune läbisõit on umbes',
          KILOMETERS:'km',
          MAINTENANCE:'Hooldus',
          LAST_SERVICE_MESSAGE:'Viimane hooldus teostati {{date}} ja läbisõit oli {{mileage}} km',
          NO_SERVICE_HISTORY:'Sellel sõidukil puudub varasem hooldusajalugu CFMOTO World süsteemis.',
          REPAIRING:'Remont',
          REPAIRING_DESCRIPTION:'Remonttöö kirjeldus',
          DESCRIPTION_TO_MECHANIC:'Remonttöö selgitus mehaanikule',
          WARRANTY:'Garantii',
          WARRANTY_DESCRIPTION:'Garantii juhtumi kirjeldus',
          WORK_ORDERER_NAME:'Töö tellija nimi',
          ENTER_WORK_ORDERER_NAME:'Sisesta töö tellija nimi',
          WORK_ORDERER_EMAIL:'Töö tellija e-post',
          ENTER_WORK_ORDERER_EMAIL:'Sisesta töö tellija e-post',
          WORK_ORDERER_PHONE:'Töö tellija telefoni number',
          ENTER_WORK_ORDERER_PHONE:'Sisesta töö tellija telefoni number',
          CREATED_BY:'Loodud kasutaja {{user}} poolt {{date}} ettevõttest {{company}}',
          add_work_to_queue:'Lisa tööjärjekorda',
          Continue:'Ava hoolduse nimekiri',
          Repairing:'Remont',
          //confirmation,
          confirmation_c:'Kinnitus',
          confirmation_text_c:'Olete kindel, et soovite väljuda sellest sõidukist?',
          confirm_c:'Kinnitan',
          cancel_c:'Tühistan',
          //user,
          log_out_user:'Logi välja ',
          reset_password: 'Lähtesta parool',
          Select_Bulletin: 'Valige Bulletin',
          Mark_as_viewed: 'Mark as viewed',
          review_progress: 'Vaadake edusamme',
          Update_maintenance_data: 'Update Maintenance data',
          assembling_created_successfully: "Kokku panemine loodud edukalt",
          confirmation_text_issue: 'Olete kindel, et soovite sõiduki väljastada omanikule?',
          confirmation_text_history:'Me loome ajaloo seoses sõiduki väljastusega!',

        }
      },
      FN:{
        translation:{
          //dashboard,
          dasboard_learn:'Opi käyttämään järjestelmää',
          DESC_USE:'Täältä löydät CFMOTO WORLD -ohjelmiston käyttöoppaat. Uuden käyttäjän tulee lukea ohjeet huolellisesti ja ymmärtää järjestelmän toiminta. Olemme laatineet oppaat jälleenmyyjälle, huoltohenkilöstölle ja ajoneuvon omistajalle.',
          Step_1:'Vaihe 1',
          Step_1_desc:'Järjestelmään liittyessään käyttäjä tunnistetaan. Jokainen henkilö on henkilökohtaisesti vastuussa käyttäjätilillään tehdyistä pyynnöistä ja syötetyistä tiedoista.',
          Step_2:'Vaihe 2',
          Step_2_desc:'Jokainen käyttäjäryhmä voi tehdä kyselyjä, syöttää tietoja ja tarkastella ajoneuvon aiempaa historiaa käyttöoikeuksiensa mukaisesti.',
          Step_3:'Vaihe 3',
          Step_3_desc:'Arvoisat yhteistyökumppanit, kaikki tänne syötetyt tiedot ovat tarpeellisia teille ja muille kumppaneille. Tietojen avulla voidaan yksinkertaistaa ajoneuvon huoltoa ja toteuttaa paras mahdollinen huolto juuri tälle ajoneuvolle.',
          search_by_VIN:'Search by VIN ...',
          select_company:'Select Company',
          select_workshop:'Select Workshop',
          add_new_work:'Add New Work',
          pending_db:'Pending',
          workshop_db:'Workshop',
          completed_db:'Completed',
          created_at_db:'Created',//:{{date}} at {{time}}',
          maintenance_db: 'Huolto',
          repairing_db:'Korjaus',
          warranty_db:'Takuu',
          //sidebar,
          Dashboard:'Ohjauspaneeli',
          Warranty_end_date:'Takuu päättymispäivä',
          Plate_number:'Rekisterinumero',
          REGISTRATION_YEAR:'Rekisteröintivuosi',
          Manufacturer:'Valmistaja',
          Engine_Name:'Moottorin nimi',
          Engine_Number:'Moottorin numero',
          work:'työmääräys',
          works:'työt',
          add:'Lisää',
          MY:'Omat',
          pending:'Odottaa',
          finished:'Valmis',
          Vehicle:'Ajoneuvo',
          Maintenance:'Huolto',
          Repairing:'Korjaus',
          Parts:'Varaosat',
          Accessories:'Lisävarusteet',
          Service_manual:'Huoltokäsikirja',
          User_manual:'Käyttöohje',
          Warranty:'TAKUU',
          Warrantynew:'Takuu',
          Bulletins:'Tiedotteet',
          Log_out:'Kirjaudu ulos ajoneuvosta',
          //vehicle data,
          Warranty_Registration:'Takuu rekisteröinti',
          History:'HISTORIA',
          SKU:'SKU',
          VIN_CODE:'VIN-KOODI',
          COUNTRY:'MAA',
          SERIES:'SARJA',
          MODEL_NAME:'MALLI',
          FACTORY_NAME:'TEHDASNIMITYS',
          COLOR:'VÄRI',
          EU_TYPE_APPROVAL:'EU-TYYPPIHYVÄKSYNTÄ',
          STEERING_POWER:'OHJAUSVOIMA',
          WHEELS:'PYÖRÄT',
          SCREEN:'NÄYTTÖ',
          LIGHTS:'VALOT',
          CARGO_COMPARTMENTS:'TAVARATILAT',
          COMMUNICATION_TERMINAL:'VIESTINTÄPÄÄTE',
          OWNER_NAME:'OMISTAJAN NIMI',
          OWNER_PHONE:'OMISTAJAN PUHELINNUMERO',
          OWNER_EMAIL:'OMISTAJAN SÄHKÖPOSTI',
          VEHICLE_NUMBER_PLATE:'REKISTRINUMERO',
          IMPORTER:'MAAHANTUOJA',
          DEALER:'KAUPPIAS',
          //maintenance,
          Select_the_mileage:'Valitse mittarilukema',
          next:'Seuraava',
          Mileage_at_maintenance:'Mittarilukema huollossa',
          Maintenance_description:'Huollon kuvaus',
          Spare_parts_for_service:'Huollossa käytetyt varaosat:',
          Vehicle_photos:'Ajoneuvon kuvat:',
          Vehicle_photos_desc:'Kuva nopeusmittarista (numeroiden tulee olla luettavissa), VIN-koodi (numeroiden tulee olla luettavissa), 45° etunäkymä ja 45° takanäkymä',
          Maintainence_list_desc:'Huoltopalvelun tarkistuslista',
          Maintainence_list_desc_2:'Merkitse, kun olet suorittanut kunkin osion',
          Mechanic_comment:'Mekaanikon kommentti:',
          MainEnterVINnumbertainence_button_text:'Tallenna huoltotiedot',
          check_all_m:'Check All',
          comment_m:'Comment',
          //warranty,
          waranty_milage:'Mittarilukema huollossa',
          Failure_description:'Vian kuvaus',
          Upload_photos_and_videos:'Lataa kuvat ja videot',
          Replaced_parts:'Vaihdetut osat',
          Cause:'Syy',
          repair:'Korjaus',
          Remarks:'Huomautukset',
          Review:'Arvio',
          Save_warranty_data:'Tallenna takuutiedot',
          Update_warranty_data:'Update warranty data',
          //repairing,
          Mileage_of_Repairing:'Korjauksen kilometrimäärä',
          Customer_description_of_errors:'Omistajan vikakuvaus',
          The_receiver_oftheworks_description:'Kuvaus korjauksesta',
          Feedback_from_the_mechanic:'Mekaanikon kommentti',
          Save_repairing_Data:'Tallenna korjaustiedot',
          Update_repairing_data:'Päivittää korjaustyötiedot',
          //bulletin,
          Bulletins_description:'Tiedotteiden kuvaus',
          Factory_instructions:'Tehtaan ohjeet',
          OPEN_FILE:'AVAA TIEDOSTO',
          buletin_Mechanic_comment:'Mekaanikon kommentti',
          Bulletin_executed:'Tiedote toteutettu',
          //warranty registration,
          Company:'YHTIÖ',
          registration_desc:'Ajoneuvo on luovutettu omistajalle. Kaikki tarvittavat käyttöohjeet on annettu.',
          //vin number page,
          EnterVINnumber:'Syötä VIN-numero/Kilven-numero',
          vinnumber:'VIN-numero',
          platenumber:'Kilven-numero',
          SUBMIT:'LÄHETÄ',
          OR:'tai',
          Addspeedometer:'Lisää nopeusmittari',
          addvincode:'Lisää VIN-koodi',
          add45front:'Lisää 45° etunäkymä',
          add45back:'Lisää 45° näkymä takaa',
          addleft:'Lisää vasen näkymä',
          addright:'Lisää oikea näkymä',
          instruction:'Henkilön nimi, jolle annettiin ohjeistus ajoneuvosta',
          company_instruction:'Sen henkilön nimi, jolle opastettiin',
          //assembling,
          check_the_vin_code:'Tarkista VIN-koodi',
          and_make_photo:'ja ota kuva',
          assembly_confirmation:'Vahvistan, että kokoonpano suoritettiin ohjeiden mukaisesti.',
          mechanic_comment:'Mekaanikon kommentti',
          issues_found:'Avaa takuuasia',
          completed_assembly:'Kokoonpano valmis',
          issues_found_during_the_assembly_process:'Kokoonpanon aikana havaitut ongelmat.',
          open_warranty:'Avaa takuu',
          add_photo:'Lisää kuva',
          add_video:'Lisää video',
          assembling:'KOKOONPANO',
          //add work,
          ADD_WORK:'Lisää töitä',
          VEHICLE_OWNER_DESCRIPTION:'Ajoneuvon omistajan kuvaus',
          WRITE_VEHICLE_OWNER_DESCRIPTION:'Kirjoita kuvaus ajoneuvon omistajasta',
          CURRENT_MILEAGE:'Nykyinen mittarilukema on noin',
          KILOMETERS:'km',
          MAINTENANCE:'Huolto',
          LAST_SERVICE_MESSAGE:'The Last service was performed on {{date}}, and the mileage was {{mileage}} km',
          NO_SERVICE_HISTORY:'This vehicle has no previous service history in the CFMOTO World system.',
          REPAIRING:'Korjaus',
          REPAIRING_DESCRIPTION:'Korjauksen kuvaus',
          DESCRIPTION_TO_MECHANIC:'Korjauksen työkuvaus mekaanikolle',
          WARRANTY:'Takuu',
          WARRANTY_DESCRIPTION:'Takuutapauksen kuvaus',
          WORK_ORDERER_NAME:'Työn tilaajan nimi',
          ENTER_WORK_ORDERER_NAME:'Anna työn tilaajan nimi',
          WORK_ORDERER_EMAIL:'Työn tilaajan sähköposti',
          ENTER_WORK_ORDERER_EMAIL:'Anna työn tilaajan sähköposti',
          WORK_ORDERER_PHONE:'Työn tilaajan puhelin',
          ENTER_WORK_ORDERER_PHONE:'Anna työn tilaajan puhelinnumero',
          CREATED_BY: 'Luonut {{user}} päivämääränä {{date}} yrityksestä {{company}}',
          add_work_to_queue:'Add to work queue',
          Continue:'Open checklist',
          Repairing:'Korjaus',
          //confirmation,
          confirmation_c:'Vahvistus',
          confirmation_text_c:'Oletko varma, että haluat kirjautua ulos ajoneuvosta?',
          confirm_c:'Vahvista',
          cancel_c:'Peruuta',
          //user,
          log_out_user:'Logout',
          reset_password: 'Reset Password',
          Select_Bulletin: 'Valitse Bulletin',
          Mark_as_viewed: 'Mark as viewed',
          review_progress: 'Review Progress',
          assembling_created_successfully: "Kokoonpano luotu onnistuneesti",
          confirmation_text_issue: 'Olete kindel, et soovite sõiduki väljastada omanikule?',
          confirmation_text_history:'Me loome ajaloo seoses sõiduki väljastusega!',

        }
      },
      LV:{
        translation:{
          //dashboard,
          dasboard_learn:'Uzziniet, kā izmantot šo sistēmu',
          DESC_USE:'Šeit jūs atradīsiet vienkāršas pamācības par CFMOTO WORLD programmatūras lietošanu. Jaunam lietotājam ir rūpīgi jāizlasa un jāsaprot, kā sistēma darbojas. Mēs esam izveidojuši ceļvežus izplatītājam, apkalpojošajam personālam un transportlīdzekļa īpašniekam.',
          Step_1:'1. darbība',
          Step_1_desc:'Katrs, kas pievienojas sistēmai, identificē sevi un sistēma identificē konkrētu personu. Katra persona ir personīgi atbildīga par pieprasījumiem, kas veikti, izmantojot savu lietotāja kontu, un ievadītajiem datiem.',
          Step_2:'2. darbība',
          Step_2_desc:'Katra lietotāju grupa atbilstoši atļautajām tiesībām var veikt vaicājumus, ievadīt datus un apskatīt transportlīdzekļa iepriekšējo vēsturi.',
          Step_3:'3. darbība',
          Step_3_desc:'Cienījamie partneri, visa šeit ievadītā informācija ir nepieciešama jums un citiem partneriem. Lai vienkāršotu transportlīdzekļa apkopi un zinātu, kā vislabāk veikt apkopi šim konkrētajam transportlīdzeklim.',
          search_by_VIN:'Search by VIN ...',
          select_company:'Select Company',
          select_workshop:'Select Workshop',
          add_new_work:'Add New Work',
          pending_db:'Pending',
          workshop_db:'Workshop',
          completed_db:'Completed',
          created_at_db: 'Created:',//{{date}} at {{time}}',
          at_db:'at',
          maintenance_db:'Maintenance',
          repairing_db:'Repairing',
          warranty_db:'Warranty',
          //sidebar,
          Dashboard:'Mērinstrumentu panelis',
          Warranty_end_date:'Warranty end date',
          Plate_number:'Plate number',
          REGISTRATION_YEAR:'REGISTRATION YEAR',
          Manufacturer:'Manufacturer',
          Engine_Name:'Engine name',
          Engine_Number:'Engine number',
          work:'strādāt',
          works:'darbojas',
          add:'Pievienot',
          MY:'Mana',
          pending:'Gaida',
          finished:'Pabeigts',
          Vehicle:'Transportlīdzeklis',
          Maintenance:'Apkope',
          Repairing:'Remonts',
          Parts:'Detaļas',
          Accessories:'Piederumi',
          Service_manual:'Servisa rokasgrāmata',
          User_manual:'Lietotāja rokasgrāmata',
          Warranty:'Garantija',
          Warrantynew:'Garantija',
          Bulletins:'Biļetens',
          Log_out:'Transportlīdzeklis Izlogoties',
          //vehicle data,
          Warranty_Registration:'Garantijas reģistrācija',
          History:'Vēsture',
          SKU:'SKU',
          VIN_CODE:'VIN KODS',
          COUNTRY:'VALSTS',
          SERIES:'SĒRIJA',
          MODEL_NAME:'MODEĻA NOSAUKUMS',
          FACTORY_NAME:'RŪPNĪCAS NOSAUKUMS',
          COLOR:'KRĀSA',
          EU_TYPE_APPROVAL:'EU TIPA APSTIPRINĀJUMS',
          STEERING_POWER:'STURES Pastiprinātājs',
          WHEELS:'RITEŅI',
          SCREEN:'EKRĀNS',
          LIGHTS:'GAISMAS',
          CARGO_COMPARTMENTS:'Bagāžas nodalījums',
          COMMUNICATION_TERMINAL:'KOMUNIKĀCIJAS TERMINĀLS',
          OWNER_NAME:'ĪPAŠNIEKA VĀRDS',
          OWNER_PHONE:'ĪPAŠNIEKA TĀLRUNIS',
          OWNER_EMAIL:'ĪPAŠNIEKA E-PASTs',
          VEHICLE_NUMBER_PLATE:'VEHICLE NUMBER PLATE',
          IMPORTER:'IMPORTĒTĀJS',
          DEALER:'Izplatītājs',
          //maintenance,
          Select_the_mileage:'Izvēlieties nobraukumu',
          next:'Nākamais',
          Mileage_at_maintenance:'Nobraukums apkopes laikā',
          Maintenance_description:'Tehniskās apkopes apraksts',
          Spare_parts_for_service:'Rezerves daļas servisam:',
          Vehicle_photos:'Transportlīdzekļa fotogrāfijas:',
          Vehicle_photos_desc:'Spidometra fotoattēls (skaitļiem jābūt salasāmiem), VIN kodam (skaitļiem jābūt salasāmiem), 45° skats no priekšpuses un 45° skats no aizmugures',
          Maintainence_list_desc:'Maintenance service checklist',
          Maintainence_list_desc_2:'Lūdzu, atzīmējiet, kad esat pabeidzis katru sadaļu',
          Mechanic_comment:'Mehāniķa komentārs:',
          Maintainence_button_text:'Saglabājiet apkopes datus',
          check_all_m:'Check All',
          comment_m:'Comment',
          //warranty,
          waranty_milage:'Nobraukums servisā',
          Failure_description:'Kļūdu apraksts',
          Upload_photos_and_videos:'Augšupielādējiet fotoattēlus un videoklipus',
          Replaced_parts:'Nomainītas detaļas',
          Cause:'Cēlonis',
          repair:'Remonts',
          Remarks:'Piezīmes',
          Review:'Pārskats',
          Save_warranty_data:'Saglabājiet garantijas datus',
          Update_warranty_data:'Update warranty data',
          //repairing,
          Mileage_of_Repairing:'Remonta nobraukums',
          Customer_description_of_errors:'Transportlīdzekļa īpašnieka apraksts',
          The_receiver_oftheworks_description:'Remonta apraksts',
          Feedback_from_the_mechanic:'Atsauksmes no mehāniķa',
          Save_repairing_Data:'Saglabājiet remonta datus',
          Update_repairing_data:'Update repairing data',
          //bulletin,
          Bulletins_description:'Biļetena apraksts:',
          Factory_instructions:'Rūpnīcas instrukcijas',
          OPEN_FILE:'ATVERT FAILU',
          buletin_Mechanic_comment:'Mechanic comment:',
          Bulletin_executed:'Biļetens izpildīts',
          //warranty registration,
          Company:'Uzņēmums',
          registration_desc:'Automašīna ir nodota īpašniekam. Īpašniekam ir sniegta visa nepieciešamā instruktāža.',
          //vin number page,
          EnterVINnumber:'Enter VIN/Plate number',
          vinnumber:'VIN numurs',
          platenumber:'Plāksnes numurs',
          SUBMIT:'IESNIEGT',
          OR:'vai',
          Addspeedometer:'Pievienojiet spidometru',
          addvincode:'Pievienojiet VIN kodu',
          add45front:'Pievienojiet 45° priekšējo skatu',
          add45back:'Pievienojiet 45° aizmugures skatu',
          addleft:'Pievienot kreiso skatu',
          addright:'Pievienojiet labo skatu',
          instruction:'Tās personas vārds, kura saņēma norādījumus',
          company_instruction:'Only if the vehicle is sold to a Company',
          //assembling,
          check_the_vin_code:'Check the vin code',
          and_make_photo:'and make photo',
          assembly_confirmation:'I confirm that the assembly was carried out according to the instructions.',
          mechanic_comment:'Mechanic comment',
          issues_found:'Issues found',
          completed_assembly:'Completed assembly',
          issues_found_during_the_assembly_process:'Issues found during the assembly process.',
          open_warranty:'Open warranty',
          add_photo:'Pievienot fotoattēlu',
          add_video:'Pievienot video',
          assembling:'SALIKŠANA',
          //add work,
          ADD_WORK:'Add Work',
          VEHICLE_OWNER_DESCRIPTION:'Vehicle Owner Description',
          WRITE_VEHICLE_OWNER_DESCRIPTION:'Write vehicle owner description',
          CURRENT_MILEAGE:'The current mileage is approx',
          KILOMETERS:'km',
          MAINTENANCE:'Maintenance',
          LAST_SERVICE_MESSAGE:'The Last service was performed on {{date}}, and the mileage was {{mileage}} km',
          NO_SERVICE_HISTORY:'This vehicle has no previous service history in the CFMOTO World system.',
          REPAIRING:'Repairing',
          REPAIRING_DESCRIPTION:'Repairing Description',
          DESCRIPTION_TO_MECHANIC:'Description to mechanic',
          WARRANTY:'Warranty',
          WARRANTY_DESCRIPTION:'Warranty description',
          WORK_ORDERER_NAME:'Work Orderer Name',
          ENTER_WORK_ORDERER_NAME:"Enter work orderer's name",
          WORK_ORDERER_EMAIL:'Work Orderer Email',
          ENTER_WORK_ORDERER_EMAIL:"Enter work orderer's email",
          WORK_ORDERER_PHONE:'Work Orderer Phone Number',
          ENTER_WORK_ORDERER_PHONE:"Enter work orderer's phone number",
          CREATED_BY: 'Izveidoja {{user}} {{date}} no uzņēmuma {{company}}',
          add_work_to_queue:'Add to work queue',
          Continue:'Open checklist',
          Repairing:'Remonts',
          //confirmation,
          confirmation_c:'Confirmation',
          confirmation_text_c:'Are you sure you want to log out of this vehicle?',
          confirm_c:'Confirm',
          cancel_c:'Cancel',
          //user,
          log_out_user:'Logout',
          reset_password: 'Reset Password',
          Select_Bulletin: 'Atlasiet Biļetens',
          Mark_as_viewed: 'Mark as viewed',
          review_progress: 'Review Progress',
          assembling_created_successfully: "Salikšana veiksmīgi izveidota",
          confirmation_text_issue: 'Olete kindel, et soovite sõiduki väljastada omanikule?',
          confirmation_text_history:'Me loome ajaloo seoses sõiduki väljastusega!',

        }
      }
    },
    lng:defaultLanguage,
    fallbackLng:'EN',
    interpolation:{
      escapeValue:false
    }
  });

export default i18n;
