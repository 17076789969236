import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import MultiSelectDropdown from '../components/Common/dropdown';
import Header from '../components/Common/Header';
import Sidebar from '../components/Common/Sidebar';
import { loaderActions, vinnActions } from '../state/actions';
import { AddValueAndLabel } from './../utils/functions';

function Dashboard({ vinnnoInRequest }) {
  const { t } = useTranslation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [worksData, setWorksData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [workshopData, setWorkshopData] = useState([]);
  const [showModal2, setShowModal2] = useState(false);
  const hideWelcome = () => {
    document.getElementById('welcome-div').style.display = 'none';
    localStorage.setItem('hide_welcome', 'true');
  };
  const shouldHideWelcome = localStorage.getItem('hide_welcome') === 'true';

  async function createHistory(work_id) {
    try {
      var formdata = new FormData();
      const profile = localStorage.getItem('profile');
      const company_name = localStorage.getItem('company_name');
      const data = localStorage.getItem('vinnData');
      const parsed_data = JSON.parse(data);
      const db_data = parsed_data?.data;

      formdata.append(
        'description',
        `Vehicle handed over by ${profile} from ${company_name} to the Owner.`
      );
      formdata.append(
        'estonian_description',
        `Sõiduki andis üle ${profile} ettevõttest ${company_name}.`
      );
      formdata.append(
        'latvian_description',
        `Vehicle handed over by ${profile} from ${company_name} to the Owner.`
      );
      formdata.append(
        'finnish_description',
        `Vehicle handed over by ${profile} from ${company_name} to the Owner.`
      );
      formdata.append('historical_note', ``);
      formdata.append('vehicle', db_data?.id);
      formdata.append('owner_email', db_data?.orderer_email || '');
      formdata.append('plate_number', db_data?.plate_number);
      formdata.append('vin_code', db_data?.vin_code);
      formdata.append('work_id', work_id || null);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/history/create/`,
        {
          method: 'POST',
          body: formdata,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create history');
      }

      return await response.text();
    } catch (error) {
      console.error('Error creating history:', error);
      throw error;
    }
  }
  async function logout_vin_and_login_function(item, shouldNavigate) {
    const userEmail = localStorage.getItem('profile_email');
    const vinnData = JSON.parse(localStorage.getItem('vinnData'));

    if (vinnData && vinnData.data && vinnData.data.vin_code) {
      const vinCode = vinnData.data.vin_code;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
      );

      if (response.ok) {
        const number = { numb: item?.vin_code };
        if (shouldNavigate) {
          await vinnnoInRequest({ number: number, navigate: navigate });
        } else {
          await vinnnoInRequest({ number: number });
        }
      }
    } else {
      const number = { numb: item?.vin_code };
      if (shouldNavigate) {
        await vinnnoInRequest({ number: number, navigate: navigate });
      } else {
        await vinnnoInRequest({ number: number });
      }
    }
  }

  const filteredWorks = worksData.filter((work) =>
    work.vin_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits (e.g., '03' instead of '3')
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0'); // Ensure hour is 2 digits
    const minute = String(date.getMinutes()).padStart(2, '0'); // Ensure minute is 2 digits

    return `${day}/${month}/${year} ${t('at_db')} ${hour}:${minute}`;
  };

  const renderWorkItems = (status) => {
    const getStatusIcon = (complete) => {
      const iconProps = {
        xmlns: 'http://www.w3.org/2000/svg',
        className: 'h-6 w-6 mr-2 text-green-400',
        fill: 'none',
        viewBox: '0 0 24 24',
        stroke: complete ? 'currentColor' : 'red',
      };
      const pathData = complete
        ? 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
        : 'M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z';

      return (
        <svg {...iconProps}>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d={pathData}
          />
        </svg>
      );
    };
    async function logout_function() {
      const userEmail = localStorage.getItem('profile_email');
      const vinnData = JSON.parse(localStorage.getItem('vinnData'));
      console.log('Here i am trying to logout', userEmail, vinnData);

      if (vinnData && vinnData.data && vinnData.data.vin_code) {
        const vinCode = vinnData.data.vin_code;
        await fetch(
          `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
        );
      }
    }

    const handleDeliverAddWork = async (work_id, item_vin_code) => {
      try {
        dispatch(loaderActions.showLoader());

        const vinnData = JSON.parse(localStorage.getItem('vinnData'));
        const currentVinCode = vinnData?.data?.vin_code;

        if (currentVinCode !== item_vin_code) {
          await logout_vin_and_login_function(
            { vin_code: item_vin_code },
            false
          );
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        const data = JSON.stringify({
          status: 7,
        });

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/addwork/add-work/${work_id}/`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: data,
          }
        );

        if (response.status === 404) {
          console.error('PATCH request failed with 404');
          toast.error('Error updating work status');
          return;
        }

        if (response.status === 200) {
          await createHistory(work_id);
          toast.success('Vehicle delivered successfully');
          window.location.href = '/dashboard';
        } else {
          console.error(`Unexpected response status: ${response.status}`);
          toast.error('Error updating work status');
        }
      } catch (error) {
        console.error('Error in handleDeliverAddWork:', error);
        toast.error('Error delivering vehicle');
      } finally {
        dispatch(loaderActions.hideLoader());
      }
    };

    return filteredWorks
      .filter((item) => item.status === status)
      .map((item) => {
        if (item.status === 7) return null;

        return (
          <div
            key={item.work_id}
            className='p-4 mb-2 shadow-lg cursor-pointer border border-gray-600 rounded-lg bg-black-400'
            onClick={() =>
              status !== 3 &&
              status !== 6 &&
              logout_vin_and_login_function(item, true)
            }
          >
            <span className='font-semibold text-f_12_l_15 md:text-f_14_l_22'>
              {item.vin_code}
            </span>
            <p className='text-sm text-gray-600'>
              {t('created_at_db')} {formatDate(item.created_at)}
            </p>
            <div className='mt-2 flex flex-wrap gap-2'>
              {item.is_maintenance && (
                <div className='flex items-center'>
                  <span className='text-sm'>{t('maintenance_db')}</span>
                  {getStatusIcon(item.is_maintenance_complete)}
                </div>
              )}
              {item.is_repair && (
                <div className='flex items-center'>
                  <span className='text-sm'>{t('repairing_db')}</span>
                  {getStatusIcon(item.is_repair_complete)}
                </div>
              )}
              {item.is_warranty && (
                <div className='flex items-center'>
                  <span className='text-sm'>{t('warranty_db')}</span>
                  {getStatusIcon(item.is_warranty_complete)}
                </div>
              )}
            </div>
            {status === 3 && (
              <div className='flex space-x-2 mt-2'>
                <button
                  className='px-2 bg-gradient rounded-[10px] text-f_16_l_20 font-supremeMedium'
                  onClick={(e) => {
                    e.stopPropagation();
                    logout_vin_and_login_function(item, false);
                    navigate(`/progress?workId=${item?.work_id}`);
                  }}
                >
                  {t('review_progress')}
                </button>
              </div>
            )}
            {status === 6 && (
              <div className='flex space-x-2 mt-2'>
                <button
                  className='px-2 bg-green-500 rounded-[10px] text-f_16_l_20 font-supremeMedium text-white'
                  onClick={() => setShowModal2(true)}
                >
                  {t('Issued_To_Owner')}
                </button>
              </div>
            )}
            <Modal
              isOpen={showModal2}
              onRequestClose={() => setShowModal2(false)}
              className='fixed inset-0 flex items-center justify-center z-50'
              overlayClassName='fixed inset-0 bg-black bg-opacity-50'
            >
              <div className='bg-gradient rounded-lg p-6'>
                <h1 className='text-xl font-bold mb-4'>Confirmation</h1>
                <p className='mb-6 text-black'>
                  {t('confirmation_text_issue')}
                </p>
                <p className='mb-6 text-black'>
                  {t('confirmation_text_history')}
                </p>

                <div className='flex justify-end mt-6'>
                  <button
                    className='px-4 py-2 text-white rounded hover:bg-blue-700 mr-2'
                    style={{ backgroundColor: '#009bb4' }}
                    onClick={() => {
                      handleDeliverAddWork(item?.work_id, item?.vin_code);
                      setShowModal2(false);
                    }}
                  >
                    Confirms
                  </button>
                  <button
                    className='px-4 py-2 bg-white text-black rounded hover:bg-gray-500'
                    onClick={() => setShowModal2(false)} // Close modal on cancel
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        );
      });
  };
  const userData = JSON.parse(localStorage.getItem('userData'));
  const isDealerAdmin = userData?.profile?.role === 'Dealer_admin';
  const isAdmin = userData?.profile?.role === 'admin';
  const MechanicCompanyId = userData?.profile?.company?.id;
  const userId = userData?.profile?.id;
  const companyId = isDealerAdmin
    ? MechanicCompanyId
    : isAdmin
    ? selectedCompany?.id || null
    : MechanicCompanyId;

  useEffect(() => {
    dispatch(loaderActions.showLoader());
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/addwork/add-work?company=${companyId}&workshop=${
          selectedWorkshop?.id ? selectedWorkshop?.id : null
        }`
      )
      .then((response) => {
        setWorksData(response.data);
      })
      .catch((error) => {
        toast.error('Error fetching works.');
      })
      .finally(() => {
        dispatch(loaderActions.hideLoader());
      });
    getCompanies();
    if (selectedCompany?.id || companyId) getWorkshops();
    resetData();
  }, [selectedCompany, selectedWorkshop]);

  const getCompanies = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/company-workshops/${
          companyId ? `?company_id=${companyId}` : ''
        }`
      )
      .then((response) => {
        setCompanyData(
          isAdmin ? response?.data?.companies : [response?.data?.company]
        );
      })
      .catch((error) => {
        toast.error('Error fetching companies.');
      });
  };

  const getWorkshops = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/company-workshops/${
          !isAdmin && !isDealerAdmin
            ? `?user_id=${userId}`
            : companyId
            ? `?company_id=${companyId}&include_workshops=true`
            : ''
        }`
      )
      .then((response) => {
        setWorkshopData(
          !isAdmin && !isDealerAdmin
            ? [response?.data?.workshop]
            : response?.data?.workshops
        );
      })
      .catch((error) => {
        toast.error('Error fetching workshops.');
      });
  };

  const resetData = () => {
    if (!selectedCompany) {
      setWorkshopData([]);
      setSelectedWorkshop('');
    }
  };

  return (
    <>
      <div className='flex'>
        <Sidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          activeMe='dashboard'
        />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='min-h-[100vh] md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          {/* DASHBOARD */}
          <div className='pt-10 pb-[10.4375rem] py-[3.0625rem] xl:py-[3.8125rem] px-[26px] md:px-8 xl:px-[3.4375rem]'>
            {!shouldHideWelcome && (
              <div
                id='welcome-div'
                className='bg-black-200 pt-[30px] md:pt-9 pl-5 md:pl-10 xl:pl-[3.4375rem] pr-3 md:pr-8 pb-[5.3125rem] xl:pb-[6.375rem] rounded-[25px]'
              >
                {/* TITLE */}
                <div className='mb-4 md:mb-[38px] flex justify-between items-center'>
                  <h1 className='text-f_45_l_57 font-supremeMedium'>
                    Welcome {localStorage.getItem('profile')}
                  </h1>
                  <button
                    onClick={hideWelcome}
                    className='text-red-500 text-2xl font-bold hover:text-red-700'
                  >
                    &times;
                  </button>
                </div>

                {/* Learn How to use this System */}
                <div className='mb-[18px] md:mb-8 xl:mb-9'>
                  <h3 className='bg-gradient-text text-f_25_l_32 mb-3 md:mb-2.5'>
                    {/* Learn How to use this System */}
                    {t('dasboard_learn')}
                  </h3>
                  <p className='text-f_18_l_28'>{t('DESC_USE')}</p>
                </div>

                {/* STEPS */}
                <div className='space-y-3 md:space-y-[5px] xl:space-y-[18px] mb-4 md:mb-6 xl:mb-11'>
                  {/* STEP 1 */}
                  <div>
                    <h3 className='bg-gradient-text text-f_22_l_28 mb-[5px]'>
                      {t('Step_1')}
                    </h3>
                    <p className='text-f_18_l_28'>{t('Step_1_desc')}</p>
                  </div>

                  {/* STEP 2 */}
                  <div>
                    <h3 className='bg-gradient-text text-f_22_l_28 mb-[5px]'>
                      {t('Step_2')}
                    </h3>
                    <p className='text-f_18_l_28'>{t('Step_2_desc')}</p>
                  </div>

                  {/* STEP 3 */}
                  <div>
                    <h3 className='bg-gradient-text text-f_22_l_28 mb-[5px]'>
                      {t('Step_3')}
                    </h3>
                    <p className='text-f_18_l_28'>{t('Step_3_desc')}</p>
                  </div>
                </div>

                {/* System tutorial videos */}
              </div>
            )}

            <div className='w-full flex flex-row xsm:flex-col justify-center gap-12 xsm:gap-4 pt-16'>
              <div className='container mx-auto p-4'>
                <div className='flex justify-between items-center mb-6 gap-2'>
                  <div className='relative flex-grow'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                      />
                    </svg>
                    <input
                      type='search'
                      placeholder={t('search_by_VIN')}
                      className='pl-10 pr-4 py-2 w-full border border-white-100 rounded-[10px] bg-transparent'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  {/* <button
                    className='border border-white-100 rounded-[10px] bg-transparent font-bold py-2 px-2 rounded inline-flex items-center'
                    onClick={() => navigate('/service/Addwork')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5 mr-2'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M12 6v6m0 0v6m0-6h6m-6 0H6'
                      />
                    </svg>
                    {t('add_new_work')}
                  </button> */}
                </div>
                <div className='grid md:grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6'>
                  <div>
                    <MultiSelectDropdown
                      options={
                        AddValueAndLabel(companyData, 'id', 'company_name') ||
                        []
                      }
                      value={selectedCompany}
                      onChange={(value) => setSelectedCompany(value)}
                      name='company'
                      placeholder={t('select_company')}
                      allowTextWrap={true}
                      isMulti={false}
                    />
                  </div>
                  <div>
                    <MultiSelectDropdown
                      options={
                        AddValueAndLabel(workshopData, 'id', 'name') || []
                      }
                      value={selectedWorkshop}
                      onChange={(value) => setSelectedWorkshop(value)}
                      name='workshop'
                      placeholder={t('select_workshop')}
                      allowTextWrap={true}
                      isMulti={false}
                    />
                  </div>
                </div>
                <div className='grid md:grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6'>
                  <div className='bg-white p-6 rounded-lg shadow'>
                    <h2 className='text-xl font-bold mb-4 flex items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6 mr-2 text-gray-500'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                      {t('pending_db')}
                      <span className='ml-2 text-sm text-red border border-red px-2 py-1 rounded-full'>
                        {
                          filteredWorks.filter((item) => item.status === 1)
                            .length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(1)}
                  </div>

                  <div className='bg-white p-6 rounded-lg shadow'>
                    <h2 className='text-xl font-bold mb-4 flex items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='h-6 w-6 mr-2 text-gray-500'
                      >
                        <path d='M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z' />
                      </svg>
                      {t('workshop_db')}
                      <span className='ml-2 text-sm text-[#FFD009] border border-[#FFD009] px-2 py-1 rounded-full'>
                        {
                          filteredWorks.filter((item) => item.status === 2)
                            .length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(2)}
                  </div>

                  <div className='bg-white p-6 rounded-lg shadow'>
                    <h2 className='text-xl font-bold mb-4 flex items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6 mr-2 text-gray-500'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                      {t('completed_db')}
                      <span className='ml-2 text-sm text-blue border border-blue px-2 py-1 rounded-full'>
                        {
                          filteredWorks.filter((item) =>
                            [3, 6].includes(item.status)
                          ).length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(6)}
                    {renderWorkItems(3)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    number: state.number,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vinnnoInRequest: (number) => dispatch(vinnActions.vinnInRequest(number)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'Dashboard' })(Dashboard));
