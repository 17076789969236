import React, { useState } from 'react';
import Sidebar from '../components/Common/Sidebar';
import Header from '../components/Common/Header';
import bike_img from '../images/bike_img.png';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../state/actions';
function Warrantyregister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profileId = +localStorage.getItem('profile_id');
  const profile = localStorage.getItem('profile');
  const company_name = localStorage.getItem('company_name');
  const company_id = localStorage.getItem('company_id');

  const token = localStorage.getItem('token');

  const [openSidebar, setOpenSidebar] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});
  const [
    disableWarrantyRegistrationButton,
    setDisableWarrantyRegistrationButton,
  ] = useState(false);
  React.useEffect(() => {
    console.log('========', token);

    if (!localStorage.getItem('vinnData')) {
      navigate('/vinn');
    } else {
      getApiData();
    }
  }, []);
  const data = localStorage.getItem('vinnData');
  const parsed_data = JSON.parse(data);
  const onChangeValue = (event, key) => {
    setDataPost({
      ...dataPost,
      [key]: event.target.value,
    });
  };
  const [dataPost, setDataPost] = useState({
    // maintanence_id: 3,
    vehicle: 1,
    owner_name: '',
    owner_email: '',
    owner_phone: '',
    vehicle_number_plate: '',
    company: '',
    necessary_instructions: '',
  });
  const dispatch = useDispatch();

  const handleButtonClicknew = async () => {
    setDisableWarrantyRegistrationButton(true);
    try {
      const warrantyResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/warranty-registration/${db_data?.id}/`
      );
      if (warrantyResponse.ok) {
        const data = await warrantyResponse.json();
        if (data?.length) {
          // Show toast
          toast.error('Warranty registration already created!', {
            autoClose: 3000,
          });
          // You can also handle more actions if needed when data is found
        } else {
          // Proceed with your function if empty response
          saveWarrantyregisteration();
        }
      } else {
        // Proceed with your function if response is not OK (e.g., 404)
        saveWarrantyregisteration();
      }
    } catch (error) {
      toast.error('Error while saving warranty registeration.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      console.error('Error fetching warranty registration:', error);
      // Proceed with your function if an error occurs
      saveWarrantyregisteration();
    }
  };

  const saveWarrantyregisteration = () => {
    console.log('datapost', dataPost);

    if (!dataPost?.owner_email && !dataPost?.owner_phone) {
      toast.error('Please provide either email or phone number');
      setDisableWarrantyRegistrationButton(false);
      return;
    }
    if (dataPost?.necessary_instructions === '') {
      toast.error('Please provide necessary instructions');
      setDisableWarrantyRegistrationButton(false);
      return; // Stop execution if instructions are missing
    }

    const data = new FormData();
    data.append('vehicle', db_data?.id);
    data.append('owner_name', dataPost?.owner_name);
    data.append('owner_email', dataPost?.owner_email);
    data.append('owner_phone', dataPost?.owner_phone);
    data.append('company', dataPost?.company);
    data.append(
      'necessary_instructions',
      dataPost?.necessary_instructions || ''
    );
    data.append('user', profileId);

    if (dataPost?.vehicle_number_plate) {
      data.append('vehicle_number_plate', dataPost?.vehicle_number_plate);
    }

    console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz', data);

    // First API call to create the warranty registration
    dispatch(loaderActions.showLoader());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/warranty/create/registration/`,
        data
      )
      .then((res) => {
        console.log('Warranty registration response:', res);

        let vinnData = JSON.parse(localStorage.getItem('vinnData'));

        if (vinnData?.data) {
          // Update the specific fields inside the 'data' key using spread operator
          vinnData.data = {
            ...vinnData.data,
            owner_name: res?.data?.owner_name,
            owner_phone: res?.data?.owner_phone,
            owner_email: res?.data?.owner_email,
            plate_number: res?.data?.vehicle_number_plate,
            company: res?.data?.company,
            year: res?.data?.registration_year,
            warranty_end_date: res?.data?.warranty_end_date,
          };

          localStorage.setItem('vinnData', JSON.stringify(vinnData));
        }
        // Now make the API call to update the company information
        const updateCompanyData = {
          company: company_id, // Replace with actual company ID if needed
        };

        return axios.patch(
          `${process.env.REACT_APP_API_URL}/vehicles/update_company/${db_data?.id}/`,
          updateCompanyData
        );
      })
      .then((updateCompanyRes) => {
        console.log('Company update response:', updateCompanyRes);

        // After updating the company, create the history record
        const formdata = new FormData();
        formdata.append(
          'description',
          `Warranty registration made by ${profile} from ${company_name}`
        );
        formdata.append(
          'estonian_description',
          `Garantiiregistreering tehti ${profile} poolt ettevõttest ${company_name}`
        );
        formdata.append(
          'latvian_description',
          `Garantijas reģistrāciju veica ${profile} no uzņēmuma ${company_name}`
        );
        formdata.append(
          'finnish_description',
          `Takuu rekisteröinnin teki ${profile} yrityksestä ${company_name}`
        );
        formdata.append('historical_note', ``);
        formdata.append('vehicle', db_data?.id);
        formdata.append('owner_email', db_data?.owner_email);
        formdata.append('plate_number', db_data?.plate_number);
        formdata.append('vin_code', db_data?.vin_code);

        if (db_data?.vehicle_number_plate) {
          formdata.append('vin_code', db_data?.vehicle_number_plate);
        }

        return fetch(`${process.env.REACT_APP_API_URL}/history/create/`, {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        });
      })
      .then((response) => response.text())
      .then((result) => {
        console.log('History creation response:', result);

        toast.success('Warranty Registration saved.');

        navigate('/vehicleData'); // Navigate after the delay
      })
      .catch((error) => {
        toast.error('Error while saving warranty registeration.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setDisableWarrantyRegistrationButton(false);
        console.log('Error:', error);
      })
      .finally(() => {
        dispatch(loaderActions.hideLoader());
      });
  };

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);
    const dateStr = dateObj.toLocaleDateString('en-US');
    const parts = dateStr.split('/');
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    const timeStr = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    return `${formattedDate} ${timeStr}`;
  }

  const db_data = parsed_data?.data;
  const [db_data_history, setDb_Data] = useState(null);
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const getApiData = async () => {
    console.log('========', token);
    console.log('db_data+++++>> ', db_data);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${db_data?.sku}/history/`
      // `${process.env.REACT_APP_API_URL}/products/15/history/`
    );
    console.log('Response=====> ', res?.data);
    if (res?.data?.length) {
      const reversedData = res?.data;
      setDb_Data(res?.data);
    }
  };
  React.useEffect(() => {
    getApiData();
  }, []);
  // HISTORY LIST DATA
  // const items = [
  //   {
  //     length: 1,
  //     timestamp: "2022-01-05 16:00",
  //     description: "A vehicle ordered from the factory",
  //   },
  //   {
  //     length: 2,
  //     timestamp: "2022-03-02 8:30",
  //     description:
  //       "Vehicle registered in the system and sent out from the factory",
  //   },
  //   {
  //     length: 3,
  //     timestamp: "2022-04-11 11:18",
  //     description: "Transport from China - to Estonia",
  //   },
  //   {
  //     length: 4,
  //     timestamp: "2022-04-15 13:45",
  //     description: "Arrived in Motohobi warehouse",
  //   },
  //   {
  //     length: 5,
  //     timestamp: "2022-04-15 16:45",
  //     description: "Motorcycle assembly Ants Käbi, Motohobi",
  //   },
  //   {
  //     length: 6,
  //     timestamp: "2022-04-17 17:00",
  //     description:
  //       "Sales Contract, Motorcycle Awarded to the User Matti Kasela",
  //   },
  //   {
  //     length: 7,
  //     timestamp: "2022-08-20 12:15",
  //     description:
  //       "1000km maintenance reservation, spare parts ordered. Maintenance time 2022-08-27",
  //   },
  // ];
  const handleDownload = () => {
    const fileId = '1mLuFXX6s-_brFA8tCZCCovhO0-vfEKxD'; // Replace with your Google Drive file ID
    const url = `https://drive.google.com/file/d/16ShLrViH0V2ggHjso_ym_yWY6lJWTssu/view`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf'); // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleButtonClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/bulletins/${db_data.vin_code}/`
      );

      if (response.data.length > 0 /* Check if data is as expected */) {
        // Store data in local storage
        localStorage.setItem('bulletinData', JSON.stringify(response.data));
        console.log(response.data);
        // Change button color if data is present
        setButtonStyle({ border: '1px solid #009bb4', color: 'red' });

        // Navigate to '/service/maintenance'
        navigate('/service/bulletin');
      } else {
        // Show alert if no data
        toast.error('No data found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Error fetching data');
    }
  };

  const handleDownloadone = () => {
    const fileId = '1AZSDXHKkJWcB4W34CIUI5gUne_gfQ1Fu'; // Replace with your Google Drive file ID
    const url = `https://drive.google.com/file/d/1nLsQHMeheszp8UTU2I35BFT9vGhnOWC_/view`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'my-custom-name.pdf'); // Set your desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // // Reverse the order of the items array
  // items.reverse();
  const image_url = `${db_data?.image}`;
  return (
    <>
      <div className='flex'>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          {/* VEHICLE DATA */}
          <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
            <div className='flex flex-col md:flex-row'>
              {/* LEFT */}
              <div className='w-full md:w-1/2 md:pt-3 xl:pt-12'>
                {/* IMAGE */}
                <div className='mb-10 md:mb-20'>
                  <img src={image_url} alt='bike-img' />
                </div>
              </div>

              {/* RIGHT */}
              <div className='w-full flex flex-col md:w-1/2'>
                {/* LIST */}
                <div className='md:pl-5 xl:pl-[30px]'>
                  {/* BIKE NAME */}
                  <div className='mb-5 md:mb-[30px] xl:mb-14'>
                    <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_20_l_25 xl:text-f_27_l_34 text-center font-supremeBold'>
                      {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                    </h3>
                    {/* <button className="w-full mt-5 h-12 xl:h-[73px] rounded-[10px] border border-yellow text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase"onClick={() => navigate('/service/Waranty')}>
                    Warranty Registration
                    </button> */}
                  </div>

                  {/* LIST */}
                  <div className='space-y-3.5 xl:space-y-5'>
                    {/* SKU
                    {db_data?.sku?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('SKU')}
                        </span>
                        <span> {db_data?.sku}</span>
                      </div>
                    )} */}
                    {/* VIN CODE / PRODUCT ID */}
                    {db_data?.vin_code?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('VIN_CODE')}
                        </span>
                        <span>{db_data?.vin_code}</span>
                      </div>
                    )}
                    {/* COUNTRY */}
                    {db_data?.country?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('COUNTRY')}
                        </span>
                        <span>{db_data?.country}</span>
                      </div>
                    )}
                    {/* SERIES */}
                    {db_data?.series?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('SERIES')}
                        </span>
                        <span>{db_data?.series}</span>
                      </div>
                    )}
                    {/* MODEL NAME */}
                    {db_data?.model_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('MODEL_NAME')}
                        </span>
                        <span>{db_data?.model_name}</span>
                      </div>
                    )}
                    {/* FACTORY NAME */}
                    {db_data?.factory_name?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('FACTORY_NAME')}
                        </span>
                        <span>{db_data?.factory_name}</span>
                      </div>
                    )}
                    {/* COLOR */}
                    {db_data?.color?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('COLOR')}
                        </span>
                        <span>{db_data?.color}</span>
                      </div>
                    )}
                    {/* EU TYPE APPROVAL */}
                    {db_data?.eu_type_approval?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('EU_TYPE_APPROVAL')}
                        </span>
                        <span>{db_data?.eu_type_approval}</span>
                      </div>
                    )}
                    {/* BODY TYPE */}
                    {db_data?.body_type?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          BODY TYPE
                        </span>
                        <span>{db_data?.body_type}</span>
                      </div>
                    )}
                    {/* STEERING POWER */}
                    {db_data?.steering_power?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('STEERING_POWER')}
                        </span>
                        <span>{db_data?.steering_power}</span>
                      </div>
                    )}
                    {/* WHEELS */}
                    {db_data?.wheels?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('WHEELS')}
                        </span>
                        <span>{db_data?.wheels}</span>
                      </div>
                    )}
                    {/* SCREEN */}
                    {db_data?.screen?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('SCREEN')}
                        </span>
                        <span>{db_data?.screen}</span>
                      </div>
                    )}
                    {/* LIGHTS */}
                    {db_data?.lights?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('LIGHTS')}
                        </span>
                        <span>{db_data?.lights}</span>
                      </div>
                    )}
                    {/* CARGO COMPARTMENTS */}
                    {db_data?.cargo_compartments?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('CARGO_COMPARTMENTS')}
                        </span>
                        <span>{db_data?.cargo_compartments}</span>
                      </div>
                    )}
                    {/* COMMUNICATION TERMINAL */}
                    {db_data?.communication_terminal?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('COMMUNICATION_TERMINAL')}
                        </span>
                        <span>{db_data?.communication_terminal}</span>
                      </div>
                    )}
                    {/* DATA OF MANUFACTURE
                    {db_data?.date_of_manufacture?.toString().length > 0 && (
                      <div className="flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23">
                        <span className="text-lightWhite uppercase">
                          DATE OF MANUFACTURE
                        </span>
                        <span>{db_data?.date_of_manufacture}</span>
                      </div>
                    )} */}
                    {/* ORDERER */}
                    {db_data?.orderer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_NAME')}
                        </span>
                        <span>{db_data?.orderer}</span>
                      </div>
                    )}
                    {/* ORDERER PHONE */}
                    {db_data?.orderer_phone?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_PHONE')}
                        </span>
                        <span>{db_data?.orderer_phone}</span>
                      </div>
                    )}
                    {/* ORDERER EMAIL */}
                    {db_data?.orderer_email?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('OWNER_EMAIL')}
                        </span>
                        <span>{db_data?.orderer_email}</span>
                      </div>
                    )}
                    {/* ORDERER VEHICLE NUMBER PLATE */}
                    {db_data?.vehicle_number_plate?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('VEHICLE_NUMBER_PLATE')}
                        </span>
                        <span>{db_data?.vehicle_number_plate}</span>
                      </div>
                    )}
                    {/* IMPORTER */}
                    {db_data?.importer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('IMPORTER')}
                        </span>
                        <span>{db_data?.importer}</span>
                      </div>
                    )}
                    {/* DEALER */}
                    {db_data?.dealer?.toString().length > 0 && (
                      <div className='flex items-center justify-between text-f_12_l_15 md:text-f_14_l_18 xl:text-f_18_l_23'>
                        <span className='text-lightWhite uppercase'>
                          {t('DEALER')}
                        </span>
                        <span>{db_data?.dealer}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* HISTORY SECTION */}

            <div className='mt-10 mb-4'>
              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerName'
                  className='text-[#FFD009] font-supremeBold bg-gradient-text text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_NAME')}
                </label>
                <input
                  id='ownerName'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  onChange={(event) => onChangeValue(event, 'owner_name')}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='companyName'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('Company')}
                </label>
                <input
                  id='companyName'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  onChange={(event) => onChangeValue(event, 'company')}
                  placeholder={t('company_instruction')}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerPhone'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_PHONE')}
                  {/* <span className="text-error">*</span> */}
                </label>

                <input
                  id='ownerPhone'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  onChange={(event) => onChangeValue(event, 'owner_phone')}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='ownerEmail'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('OWNER_EMAIL')}
                </label>
                {/* <span className='text-error'>*</span> */}
                <input
                  id='ownerEmail'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  onChange={(event) => onChangeValue(event, 'owner_email')}
                />
              </div>

              <div className='flex flex-col md:flex-row w-full items-start mb-10 gap-2'>
                <label
                  htmlFor='vehicleNumberPlate'
                  className='bg-gradient-text font-supremeBold text-2xl w-1/3 md:w-1/5 mb-1 md:mb-0'
                >
                  {t('VEHICLE_NUMBER_PLATE')}
                </label>
                {/* <span className='text-error'>*</span> */}
                <input
                  id='vehicleNumberPlate'
                  type='text'
                  className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                  onChange={(event) =>
                    onChangeValue(event, 'vehicle_number_plate')
                  }
                />
              </div>

              <div className='w-full items-start mb-10'>
                <div className='mb-10'>
                  <label
                    htmlFor='vehicleHandover'
                    className='font-supremeRegular text-2xl md:w-1/5'
                  >
                    {t('registration_desc')}
                  </label>
                  <span className='text-error'>*</span>
                </div>
                <div>
                  <input
                    id='vehicleHandover'
                    type='text'
                    className='w-full bg-[#181818] border-2 border-white text-white focus:outline-none rounded-lg p-3'
                    onChange={(event) =>
                      onChangeValue(event, 'necessary_instructions')
                    }
                    placeholder={t('instruction')}
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              {' '}
              {/* Add items-center if vertical centering is also needed */}
              <button
                className='w-full sm:w-3/5 mt-10 h-10 sm:h-7 xl:h-[65px] rounded-[5px] sm:rounded-[10px] border border-[#FFD009] text-[#FFD009] font-supremeMedium text-lg xl:text-xl'
                onClick={() => handleButtonClicknew()}
                disabled={disableWarrantyRegistrationButton}
                style={
                  disableWarrantyRegistrationButton
                    ? { color: 'grey', border: 'grey 1px solid' }
                    : {}
                }
              >
                {t('Warranty_Registration')}
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Warrantyregister;
